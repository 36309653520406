import KeenSlider from "keen-slider";

document.addEventListener('astro:page-load', () => {
	const e = document.getElementById("newsPapersCarousel");
	if (!e) return;

	const $newspapersNavCnt = document.querySelector('#newspapersNavCnt');
	if (!$newspapersNavCnt) return;

	const prevBtn = $newspapersNavCnt.querySelector('[data-type="prev"]');
	const nextBtn = $newspapersNavCnt.querySelector('[data-type="next"]');
	if (!prevBtn || !nextBtn) return;

	const slider = new KeenSlider("#newsPapersCarousel", {
		breakpoints: {
			'(max-width: 430px)': {
				slides: {
					perView: 1.25,
					spacing: 20,
				}
			},
			'(min-width: 431px)': {
				slides: {
					perView: 2.5,
					spacing: 20,
				}
			},
			'(min-width: 1010px)': {
				slides: {
					perView: 4,
					spacing: 20,
				}
			}
		},
		created: (slider) => {
			const currentSlide = slider.track.details.rel
			const maxSlide = slider.track.details.maxIdx

			prevBtn.classList.toggle('disabledPointerNewspaperHeadline', currentSlide === 0)
			nextBtn.classList.toggle('disabledPointerNewspaperHeadline', currentSlide === maxSlide)
		},
		slideChanged: (slider) => {
			const currentSlide = slider.track.details.rel
			const maxSlide = slider.track.details.maxIdx

			prevBtn.classList.toggle('disabledPointerNewspaperHeadline', currentSlide === 0)
			nextBtn.classList.toggle('disabledPointerNewspaperHeadline', currentSlide === maxSlide)

			try {
				gtag('event', 'page_view', {
					page_title: `${document.title}`,
					page_location: `${location.pathname}`
				})
			} catch(err) {

			}
		},
	})

	prevBtn.addEventListener('click', slider.prev)
	nextBtn.addEventListener('click', slider.next)
});
