import type { KeenSliderInstance } from "keen-slider";

function fixCls(slider: KeenSliderInstance, _options: {}) {
	slider.on("created", S => {
		const sliderEl = S.container
		if (!sliderEl.classList.contains("fixKeen")) return
		sliderEl.classList.add("keen-slider");
		sliderEl.classList.remove("fixKeen");
	})
}

function navigation(slider: KeenSliderInstance, options: { useDots?: boolean, useArrows?: boolean, arrowIcon?: string, dotsPer?: "view" | "slide" }) {
	const { useDots = true, useArrows = true, arrowIcon = "", dotsPer = "slide" } = options
	let wrapper: HTMLElement, dots: HTMLElement, arrowLeft: HTMLElement, arrowRight: HTMLElement

	function markup(remove: boolean = false) {
		wrapperMarkup(remove)
		useDots && dotMarkup(remove)
		useArrows && arrowMarkup(remove)
	}
	function updateClasses() {
		useArrows && updateArrowClasses()
		useDots && updateDotClasses()
	}

	function removeElement(elment: HTMLElement) {
		elment.parentNode?.removeChild(elment)
	}

	function createEl(className: string = "", elementType: string = "div") {
		const div = document.createElement(elementType)
		const classNames = className.split(" ")
		classNames.forEach((name) => div.classList.add(name))
		return div
	}

	function arrowMarkup(remove: boolean = false) {
		if (remove) {
			removeElement(arrowLeft)
			removeElement(arrowRight)
			return
		}

		arrowLeft = createEl("arrow arrow--left", "button")
		arrowLeft.addEventListener("click", () => slider.prev())
		arrowRight = createEl("arrow arrow--right", "button")
		arrowRight.addEventListener("click", () => slider.next())

		if (arrowIcon.includes("svg")) {
			const arrow = new DOMParser().parseFromString(arrowIcon, "text/xml").firstChild as HTMLElement
			const svgIcon = createEl("svgIcon", "i")
			svgIcon.appendChild(arrow)

			arrowLeft.appendChild(svgIcon.cloneNode(true))
			arrowRight.appendChild(svgIcon.cloneNode(true))
		} else {
			arrowLeft.innerHTML = arrowIcon
			arrowRight.innerHTML = arrowIcon
		}

		wrapper.appendChild(arrowLeft)
		wrapper.appendChild(arrowRight)
	}

	function wrapperMarkup(remove: boolean = false) {
		if (remove) {
			const parent = wrapper.parentNode
			while (wrapper.firstChild)
				parent?.insertBefore(wrapper.firstChild, wrapper)
			removeElement(wrapper)
			return
		}
		wrapper = createEl("navigation-wrapper")

		if (!slider.container.parentNode) return
		slider.container.parentNode.appendChild(wrapper)
		wrapper.appendChild(slider.container)
	}



	function dotMarkup(remove: boolean = false) {
		if (remove) {
			removeElement(dots)
			return
		}

		dots = createEl("dots")
		switch (dotsPer) {
			case "view":
				if (!(typeof slider.options.slides == "object" && typeof slider.options.slides?.perView === "number"))
					return;

				console.log("asdsa")

				const _slidesPerView = slider.options.slides?.perView;
				if (_slidesPerView == 0)
					return;

				const numberOfViews = Math.ceil(slider.slides.length / _slidesPerView)
				Array.from({ length: numberOfViews }).map((_, idx) => {
					const dot = createEl("dot")
					dot.addEventListener("click", () => slider.moveToIdx(idx * _slidesPerView))
					dots.appendChild(dot)
				})

				break;
			case "slide":
				slider.slides.forEach((_e, idx) => {
					const dot = createEl("dot")
					dot.addEventListener("click", () => slider.moveToIdx(idx))
					dots.appendChild(dot)
				})
				break;
		}


		wrapper.appendChild(dots)
	}

	function updateDotClasses() {
		Array.from(dots.children).forEach(function (dot, idx) {
			const slide = slider.slides[idx]
			switch (dotsPer) {
				case "view":
					if (!(typeof slider.options.slides == "object" && typeof slider.options.slides?.perView === "number"))
						return;

					const _slidesPerView = slider.options.slides?.perView;
					if (_slidesPerView == 0)
						return;

					if (Math.floor(slider.track.details.rel / _slidesPerView) !== idx) {
						dot.classList.remove("dot--active")
						//@ts-ignore
						if (dot.style.backgroundColor)
							//@ts-ignore
							dot.style.backgroundColor = ""
					}

					if (Math.floor(slider.track.details.rel / _slidesPerView) === idx) {
						dot.classList.add("dot--active")
						if (slide && slide.dataset.dotColor)
							//@ts-ignore
							dot.style.backgroundColor = slide.dataset.dotColor
					}
					break;
				case "slide":
					if (idx !== slider.track.details.rel) {
						dot.classList.remove("dot--active")
						//@ts-ignore
						if (dot.style.backgroundColor)
							//@ts-ignore
							dot.style.backgroundColor = ""
					}

					if (idx === slider.track.details.rel) {
						dot.classList.add("dot--active")
						if (slide && slide.dataset.dotColor)
							//@ts-ignore
							dot.style.backgroundColor = slide.dataset.dotColor
					}
					break;
			}
		})
	}

	function updateArrowClasses() {
		const slide = slider.track.details.rel
		slide === 0
			? arrowLeft.classList.add("arrow--disabled")
			: arrowLeft.classList.remove("arrow--disabled")
		slide === slider.track.details.slides.length - 1
			? arrowRight.classList.add("arrow--disabled")
			: arrowRight.classList.remove("arrow--disabled")
	}

	slider.on("created", () => {
		markup()
		updateClasses()
		slider.update()
	})

	slider.on("optionsChanged", () => {
		markup(true)
		markup()
		updateClasses()
	})
	slider.on("slideChanged", () => {
		updateClasses()
	})
	slider.on("destroyed", () => {
		markup(true)
	})
}

const timingFuncs = {
	keenDefault: (t: number) => 1 + --t * t * t * t * t,
	easeOutCubic: (t: number) => 1 - Math.pow(1 - t, 3),

}

function autoSwitch(slider: KeenSliderInstance, options: { duration?: number, persistDuration?: number, haltOnMouseOver?: boolean, timingFunction?: keyof typeof timingFuncs }) {
	const { persistDuration = 1000, duration = 1000, haltOnMouseOver = true, timingFunction = "keenDefault" } = options

	let timeout: NodeJS.Timeout
	let mouseOver = false
	function clearNextTimeout() {
		clearTimeout(timeout)
	}
	function nextTimeout() {
		clearTimeout(timeout)
		if (mouseOver) return
		timeout = setTimeout(() => {
			slider.moveToIdx(slider.track.details.abs + 1, true, { duration, easing: timingFuncs[timingFunction] });
		}, persistDuration)
	}


	slider.on("created", () => {
		if (haltOnMouseOver) {
			slider.container.addEventListener("mouseover", () => {
				mouseOver = true
				clearNextTimeout()
			})
			slider.container.addEventListener("mouseout", () => {
				mouseOver = false
				nextTimeout()
			})
		}

		document.querySelectorAll(".dots").forEach(dot => {
			dot.addEventListener("click", clearNextTimeout)
		})

		nextTimeout()
	})



	slider.on("dragStarted", clearNextTimeout)
	slider.on("animationEnded", nextTimeout)
	slider.on("updated", nextTimeout)
}


function moveAndFade(slider: KeenSliderInstance, _options: {}) {
	let currentSlideIDx = 0
	let currentSlide = slider.slides[currentSlideIDx]

	slider.on("animationEnded", S => {
		currentSlideIDx = S.track.details.rel
		currentSlide = S.slides[currentSlideIDx]
	})

	slider.on("created", S => {
		S.slides.forEach(slideEl => {
			if (!slideEl.classList.contains("move_and_fade_slide"))
				slideEl.classList.add("move_and_fade_slide")
			const fadeImg = slideEl.getElementsByClassName("heroImg")[0] as HTMLImageElement
			if (!fadeImg) return
			if (!fadeImg.classList.contains("move_and_fade_img"))
				fadeImg.classList.add("move_and_fade_img")
		})
	})

	slider.on("detailsChanged", S => {
		S.slides.forEach((slideEl, idx) => {
			slideEl.style.opacity = timingFuncs.easeOutCubic(S.track.details.slides[idx].portion).toString();
			if (currentSlideIDx == idx) return
			const nonCurrentImg = slideEl.getElementsByClassName("heroImg")[0] as HTMLImageElement
			if (!nonCurrentImg) return
			nonCurrentImg.style.transform = `translateX(0%)`;
		})

		const currentImg = currentSlide.getElementsByClassName("heroImg")[0] as HTMLImageElement
		if (!currentImg) return
		currentImg.style.transform = `translateX(${(S.track.details.slides[currentSlideIDx].portion * 100) - 100}%)`;
	})
}

function fade(slider: KeenSliderInstance, _options: {}) {

	slider.on("created", S => {
		S.slides.forEach(slideEl => {
			if (!slideEl.classList.contains("fade_slide"))
				slideEl.classList.add("fade_slide")
		})
	})

	slider.on("detailsChanged", S => {
		S.slides.forEach((slideEl, idx) =>
			slideEl.style.opacity = S.track.details.slides[idx].portion.toString()
		)
	})
}

function setActive(slider: KeenSliderInstance, options: { centered?: boolean }) {
	const { centered = false } = options
	function _setActive(centered: boolean) {
		const currentIdx = slider.track.details.abs + (centered ? 1 : 0);
		slider.slides.forEach((S, idx) => {
			S.classList.remove("active");
			if (idx == currentIdx) S.classList.add("active");
		});
	}
	slider.on("created", () => _setActive(centered))
	slider.on("slideChanged", () => _setActive(centered))
	slider.on("updated", (slider) => {
		// @ts-ignore
		if (slider.options.slides?.perView == 1)
			_setActive(false)
		// @ts-ignore
		if (slider.options.slides?.perView == 3)
			_setActive(centered)

	})
}


export default {
	navigation,
	fixCls,
	autoSwitch,
	moveAndFade,
	fade,
	setActive
}