import { Media } from "components/library/artDirection/utils";
import KeenSlider from "keen-slider";

const homeCarouselAutoTrigger = 8000;

class HomeCarousel extends HTMLElement {

	slider;
	$container;
	$homeCarousel;
	$homeCarouselThumbs;
	inited = false;
	ready = false;
	tmrAutoMove = null;
	isMoveOver = false;
	$slides;

	constructor() {
		super();

		if (!this.inited)
			this.initSlider();
	}

	moveSlider = () => {
		//fail safe
		if (this.isMoveOver)
			return;

		this.slider.next();

		//set next tick
		if (!this.slider.options.disabled)
			this.tmrAutoMove = setTimeout(this.moveSlider, homeCarouselAutoTrigger);
	}

	onMouseEnter = () => {
		this.isMoveOver = true;

		if (this.tmrAutoMove) {
			clearInterval(this.tmrAutoMove);
			this.tmrAutoMove = null;
		}
	}

	onMouseLeave = () => {
		this.isMoveOver = false;

		if (this.tmrAutoMove);
		clearInterval(this.tmrAutoMove);

		if (!this.slider.options.disabled)
			this.tmrAutoMove = setTimeout(this.moveSlider, homeCarouselAutoTrigger);
	}

	setActive = (activeSlideIdx) => {
		this.$homeCarouselThumbs.querySelectorAll('.thb').forEach(($T, idx) => {
			$T.classList.toggle('active', idx == activeSlideIdx);

			if (window.innerWidth > 768 && idx == activeSlideIdx) {
				$T.querySelector('.desktop animate').beginElement();
			}
		})

		this.$homeCarousel.querySelectorAll('.keen-slider__slide').forEach(($T, idx) => {
			$T.classList.toggle('active', idx == activeSlideIdx);
		})
	}

	initSlider = () => {
		this.$homeCarousel = this.querySelector('.homeCarousel');
		this.$homeCarouselThumbs = this.querySelector('.homeCarouselThumbs');
		this.$container = this.querySelector(".container");

		if (!this.$homeCarousel)
			return;

		this.slider = new KeenSlider(
			this.$homeCarousel,
			{
				loop: true,
				disabled: true,
				breakpoints: {
					[`(min-width: ${Media.sm})`]: {
						disabled: false,
					},
				},
				created: (thisSlider) => {
					this.ready = true;

					if (thisSlider.options.disabled)
						return;

					setTimeout(this.moveSlider, homeCarouselAutoTrigger)
				},
				slideChanged: (thisSlider) => {
					const currentSlideIdx = thisSlider.track.details.rel
					this.setActive(currentSlideIdx);
				},
				optionsChanged: (thisSlider) => {
					//kill auto move if resized
					if (thisSlider.options.disabled && this.tmrAutoMove) {
						clearTimeout(this.tmrAutoMove);
						this.tmrAutoMove = false;
					}
				}
			}
		)

		this.$slides = this.querySelectorAll('.keen-slider__slide');

		this.$container.addEventListener('mouseenter', this.onMouseEnter);
		this.$container.addEventListener('mouseleave', this.onMouseLeave);

		//thumbs handling
		this.$homeCarouselThumbs.addEventListener('click', this.onThumbClicked);
		window.addEventListener('scroll', this.scrollSpy, { passive: true });
	}

	onThumbClicked = (e) => {

		const $thb = e.target.closest('.thb');
		if (!$thb)
			return;

		const _indexClicked = $thb.dataset.idx ?? null;

		//mobile
		if (this.slider.options.disabled) {
			//set active thumb when mobile only
			e.preventDefault()
			this.setActive(_indexClicked);
			const $navElement = this.querySelector(`${$thb.getAttribute('href')}`);
			if ($navElement)
				$navElement.scrollIntoView({ behavior: "smooth", block: "start" });
			return;
		}

		e.preventDefault();
		(_indexClicked != null) && this.slider.moveToIdx(Number(_indexClicked));
	}

	scrollSpy = (e) => {
		//only for mobile
		if (window.innerWidth > 768)
			return;

		const scrollPos = window.scrollY || document.documentElement.scrollTop;
		let slideIdx = -1;
		for (const $elem of this.$slides) {
			const isActive = $elem.offsetTop - 150 <= scrollPos && $elem.offsetTop + $elem.clientHeight - 150 > scrollPos;
			slideIdx++;
			if (isActive) {
				this.setActive(slideIdx);
				break;
			}
		}
	}

	disconnectedCallback () {
		if (!this) return;

		if (this.slider)
			this.slider.destroy();

		if (this.tmrAutoMove)
			clearInterval(this.tmrAutoMove);

		this.$container.removeEventListener('mouseenter', this.onMouseEnter);
		this.$container.removeEventListener('mouseleave', this.onMouseLeave);
		this.$homeCarouselThumbs.removeEventListener('click', this.onThumbClicked);

		window.removeEventListener('scroll', this.scrollSpy);
	}

}
if (!customElements.get("home-carousel"))
	customElements.define("home-carousel", HomeCarousel);
